var _class, _temp2;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { Manifest, CanvasProvider, FullPageViewport, SingleTileSource, OpenSeadragonViewport, AnnotationProvider, AnnotationListProvider, Responsive, withBemClass } from '@canvas-panel/core';
import Container from '../Container/Container';
import TitlePanel from '../TitlePanel/TitlePanel';
import AnnotationListView from '../AnnotationListView/AnnotationListView';
import ExploreButton from '../ExploreButton/ExploreButton';
import getCurrentScrollY from '../../utils/getCurrentScrollY';
import './Viewer.scss';
import ZoomButtons from '../ZoomButtons/ZoomButtons';
import MobileAnnotationView from '../MobileAnnotationView/MobileAnnotationView';

var Viewer = (_temp2 = _class = function (_Component) {
  _inherits(Viewer, _Component);

  function Viewer() {
    var _temp, _this, _ret;

    _classCallCheck(this, Viewer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.container = null, _this.state = { viewport: null, updater: false, interactive: false, ready: false }, _this.setViewport = function (viewport) {
      _this.setState({ viewport: viewport });
    }, _this.updateIndividual = function (n) {
      if (_this.updateIndividualFunc) {
        _this.updateIndividualFunc(n);
      }
    }, _this.zoomIn = function () {
      if (_this.state.viewport) {
        _this.state.viewport.zoomIn();
      }
    }, _this.zoomOut = function () {
      if (_this.state.viewport) {
        _this.state.viewport.zoomOut();
      }
    }, _this.setUpdater = function (updater) {
      _this.setState({ updater: true });
      _this.updateIndividualFunc = updater;
    }, _this.toggleInteractive = function () {
      if (_this.state.interactive === true) {
        _this.updateIndividual(getCurrentScrollY(_this.getContainer()) / window.innerHeight);
      } else {
        _this.state.viewport.zoomOut(_this.props.zoomOutSpeed);
      }
      _this.setState({
        interactive: !_this.state.interactive
      });
    }, _this.getContainer = function () {
      return _this.container;
    }, _this.setContainer = function (container) {
      _this.container = container;
      _this.setState({ ready: true });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Viewer.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        manifest = _props.manifest,
        jsonLd = _props.jsonLd,
        title = _props.title,
        children = _props.children,
        bem = _props.bem,
        style = _props.style;
    var ready = this.state.ready;


    var manifestProps = {};
    if (manifest) {
      manifestProps.url = manifest;
    }
    if (jsonLd) {
      manifestProps.jsonLd = jsonLd;
    }
    return React.createElement(
      'div',
      { ref: this.setContainer, className: bem, style: style },
      React.createElement(ExploreButton, {
        interactive: this.state.interactive,
        onClick: this.toggleInteractive,
        style: { zIndex: 12 }
      }),
      this.state.interactive ? React.createElement(ZoomButtons, {
        onZoomIn: this.zoomIn,
        onZoomOut: this.zoomOut,
        style: { zIndex: 12 }
      }) : null,
      ready ? React.createElement(
        Manifest,
        manifestProps,
        React.createElement(
          CanvasProvider,
          null,
          React.createElement(
            FullPageViewport,
            {
              setRef: this.setViewport,
              interactive: this.state.interactive,
              getContainer: this.getContainer,
              zIndex: 5
            },
            React.createElement(
              SingleTileSource,
              { viewportController: true },
              React.createElement(OpenSeadragonViewport, {
                useMaxDimensions: true,
                osdOptions: {
                  immediateRender: false,
                  showNavigator: false
                }
              })
            )
          ),
          React.createElement(
            Responsive,
            {
              phoneOnly: function phoneOnly(props) {
                return React.createElement(
                  AnnotationListProvider,
                  props,
                  React.createElement(
                    AnnotationProvider,
                    null,
                    React.createElement(
                      MobileAnnotationView,
                      {
                        viewport: _this2.state.viewport,
                        disabled: _this2.state.interactive
                      },
                      title ? React.createElement(
                        'h1',
                        null,
                        title
                      ) : null,
                      children
                    )
                  )
                );
              }
            },
            React.createElement(
              Container,
              {
                updateIndividual: this.updateIndividual,
                updater: this.state.updater,
                disabled: this.state.interactive,
                getContainer: this.getContainer,
                style: { zIndex: 11 }
              },
              React.createElement(
                TitlePanel,
                {
                  getContainer: this.getContainer,
                  disabled: this.state.interactive
                },
                title ? React.createElement(
                  'h1',
                  null,
                  title
                ) : null,
                children,
                React.createElement(
                  'button',
                  {
                    className: bem.element('start-button'),
                    onClick: function onClick() {
                      return _this2.container.scrollTop = window.innerHeight - 10;
                    }
                  },
                  'Start tour'
                )
              ),
              React.createElement(
                AnnotationListProvider,
                null,
                React.createElement(
                  AnnotationProvider,
                  null,
                  React.createElement(AnnotationListView, {
                    annotationPosition: this.props.annotationPosition,
                    setUpdater: this.setUpdater,
                    offset: 1,
                    viewport: this.state.viewport,
                    disabled: this.state.interactive,
                    onNext: function onNext(annotation, key) {
                      return _this2.container.scrollTop = (key + 1) * window.innerHeight - 10;
                    },
                    onPrevious: function onPrevious(annotation, key) {
                      return _this2.container.scrollTop = (key + 1) * window.innerHeight;
                    }
                  })
                )
              )
            )
          )
        )
      ) : null
    );
  };

  return Viewer;
}(Component), _class.defaultProps = {
  title: null,
  children: null,
  zoomOutSpeed: 0.5,
  annotationPosition: 'top'
}, _temp2);


export default withBemClass('full-page-viewer')(Viewer);