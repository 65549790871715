var _class, _temp2;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import { FullPageViewport, SingleTileSource, withBemClass, OpenSeadragonViewport, parseSelectorTarget } from '@canvas-panel/core';

import './SwappableViewer.scss';
import ZoomButtons from '../ZoomButtons/ZoomButtons';
import FullscreenButton from '../FullscreenButton/FullscreenButton';

function getEmbeddedAnnotations(canvas) {
  return (canvas.__jsonld.annotations || []).reduce(function (list, next) {
    if (next.type === 'AnnotationPage') {
      return (next.items || []).reduce(function (innerList, annotation) {
        innerList.push(annotation);
        return innerList;
      }, list);
    }
    if (next.type === 'Annotation') {
      list.push(next);
    }
    return list;
  }, []);
}

function createRegionFromAnnotations(canvas) {
  var viewportFocuses = getEmbeddedAnnotations(canvas).filter(function (annotation) {
    return annotation.motivation === 'layout-viewport-focus';
  });
  if (viewportFocuses.length > 0) {
    return parseSelectorTarget(viewportFocuses[0].target || viewportFocuses[0].on);
  }
}

var SwappableViewer = (_temp2 = _class = function (_Component) {
  _inherits(SwappableViewer, _Component);

  function SwappableViewer() {
    var _temp, _this, _ret;

    _classCallCheck(this, SwappableViewer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      isInteractive: false,
      itemWidth: 0,
      itemHeight: 0
    }, _this.osdOptions = {
      visibilityRatio: 1,
      constrainDuringPan: true,
      showNavigator: false,
      immediateRender: false,
      preload: true
    }, _this.setViewport = function (viewport) {
      _this.viewport = viewport;
      if (_this.viewport && _this.props.region) {
        _this.viewport.goToRect(_this.props.region, 0, 0);
      }
    }, _this.isZoomedIn = function () {
      if (_this.viewport) {
        return _this.viewport.getMaxZoom() <= _this.viewport.getZoom();
      }
      return true;
    }, _this.isZoomedOut = function () {
      if (_this.viewport) {
        return _this.viewport.getMinZoom() >= _this.viewport.getZoom();
      }
      return true;
    }, _this.zoomOut = function () {
      _this.viewport.zoomOut();
    }, _this.zoomIn = function () {
      _this.viewport.zoomIn();
    }, _this.updateViewport = function (_ref) {
      var isZoomedOut = _ref.isZoomedOut;

      if (_this.state.isZoomedOut === false && isZoomedOut) {
        _this.viewport.resetView();
      }
      _this.setState({ isZoomedOut: isZoomedOut });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  SwappableViewer.prototype.componentDidMount = function componentDidMount() {
    if (this.props.region) {
      this.viewport.goToRect(this.props.region, 0, 0.0000001);
    } else {
      var region = createRegionFromAnnotations(this.props.canvas);
      if (region) {
        this.setState({ region: region });
      }
    }
  };

  SwappableViewer.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.canvas !== this.props.canvas && this.state.viewport) {
      if (nextProps.region) {
        this.viewport.goToRect(nextProps.region, 0, 0.00000001);
      } else {
        var region = createRegionFromAnnotations(this.props.canvas);
        if (region) {
          this.setState({ region: region });
        }
      }
    }
  };

  SwappableViewer.prototype.render = function render() {
    var _state = this.state,
        region = _state.region,
        isZoomedOut = _state.isZoomedOut;
    var _props = this.props,
        isInteractive = _props.isInteractive,
        manifest = _props.manifest,
        canvas = _props.canvas,
        bem = _props.bem,
        fullscreenProps = _props.fullscreenProps;


    return React.createElement(
      'div',
      {
        className: bem.element('viewport').modifiers({ interactive: isInteractive || !isZoomedOut }),
        onWheel: this.onWheel
      },
      React.createElement(
        SingleTileSource,
        { manifest: manifest, canvas: canvas },
        React.createElement(FullscreenButton, fullscreenProps),
        React.createElement(ZoomButtons, {
          onZoomOut: this.isZoomedOut() ? null : this.zoomOut,
          onZoomIn: this.isZoomedIn() ? null : this.zoomIn
        }),
        React.createElement(
          FullPageViewport,
          {
            onUpdateViewport: this.updateViewport,
            setRef: this.setViewport,
            position: 'absolute',
            interactive: isInteractive || !isZoomedOut
          },
          React.createElement(OpenSeadragonViewport, {
            useMaxDimensions: true,
            interactive: isInteractive,
            osdOptions: this.osdOptions,
            initialBounds: region
          })
        )
      )
    );
  };

  return SwappableViewer;
}(Component), _class.defaultProps = {
  isInteractive: false
}, _temp2);
SwappableViewer.propTypes = process.env.NODE_ENV !== "production" ? {
  canvas: PropTypes.any,
  manifest: PropTypes.any,
  isInteractive: PropTypes.bool,
  fullscreenProps: PropTypes.shape({
    isFullscreen: PropTypes.bool,
    isFullscreenEnabled: PropTypes.bool,
    exitFullscreen: PropTypes.func,
    goFullscreen: PropTypes.func
  })
} : {};


export default withBemClass('slide')(SwappableViewer);