function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import * as Manifesto from 'manifesto.js';
import { withBemClass } from '../Bem/Bem';

var Annotation = function (_Component) {
  _inherits(Annotation, _Component);

  function Annotation() {
    var _temp, _this, _ret;

    _classCallCheck(this, Annotation);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.handleClick = function (e) {
      return _this.props.onClick ? _this.props.onClick(_this.props.annotation, {
        x: _this.props.x || 0,
        y: _this.props.y || 0,
        width: _this.props.width,
        height: _this.props.height
      }, e) : null;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Annotation.prototype.render = function render() {
    var _props = this.props,
        style = _props.style,
        bem = _props.bem,
        bemModifiers = _props.bemModifiers,
        annotation = _props.annotation,
        annotationContent = _props.annotationContent;

    var modifiers = bemModifiers ? bemModifiers(annotation, this.props) : null;

    return React.createElement(
      'div',
      {
        className: modifiers ? bem.modifiers(modifiers) : bem,
        style: style,
        onClick: this.handleClick
      },
      annotationContent ? annotationContent(annotation, bem) : null
    );
  };

  return Annotation;
}(Component);

export default withBemClass('annotation')(Annotation);