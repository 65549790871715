function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import CanvasRepresentation from '../CanvasRepresentation/CanvasRepresentation';
import Annotation from '../Annotation/Annotation';

var AnnotationRepresentation = function (_Component) {
  _inherits(AnnotationRepresentation, _Component);

  function AnnotationRepresentation() {
    _classCallCheck(this, AnnotationRepresentation);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  AnnotationRepresentation.prototype.render = function render() {
    var _props = this.props,
        annotations = _props.annotations,
        onClickAnnotation = _props.onClickAnnotation,
        annotationStyle = _props.annotationStyle,
        growthStyle = _props.growthStyle,
        bemModifiers = _props.bemModifiers,
        annotationContent = _props.annotationContent,
        props = _objectWithoutProperties(_props, ['annotations', 'onClickAnnotation', 'annotationStyle', 'growthStyle', 'bemModifiers', 'annotationContent']);

    return React.createElement(
      CanvasRepresentation,
      props,
      annotations.map(function (_ref, key) {
        var annotation = _ref.annotation,
            on = _ref.on;
        return React.createElement(Annotation, {
          key: key,
          x: on.selector.x,
          y: on.selector.y,
          height: on.selector.height,
          width: on.selector.width,
          annotation: annotation,
          style: annotationStyle,
          onClick: onClickAnnotation,
          growthStyle: growthStyle,
          bemModifiers: bemModifiers,
          annotationContent: annotationContent
        });
      })
    );
  };

  return AnnotationRepresentation;
}(Component);

export default AnnotationRepresentation;