function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { withBemClass } from '@canvas-panel/core';
import './TitlePanel.scss';
import ScrollDownIcon from '../ScrollDownIcon/ScrollDownIcon';

var TitlePanel = function (_Component) {
  _inherits(TitlePanel, _Component);

  function TitlePanel() {
    _classCallCheck(this, TitlePanel);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  TitlePanel.prototype.render = function render() {
    var _props = this.props,
        style = _props.style,
        bem = _props.bem,
        disabled = _props.disabled,
        getContainer = _props.getContainer,
        current = _props.current;


    return React.createElement(
      'div',
      { className: bem.modifiers({ disabled: disabled }), style: style },
      React.createElement(
        'div',
        { className: bem.element('inner') },
        this.props.children
      ),
      React.createElement(ScrollDownIcon, {
        getContainer: getContainer,
        style: { opacity: current === 0 ? 1 : 0 }
      })
    );
  };

  return TitlePanel;
}(Component);

export default withBemClass('title-panel')(TitlePanel);