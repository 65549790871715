var _class, _temp2;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { AnnotationDetail, withBemClass } from '@canvas-panel/core';
import AnnotationNavigation from '../AnnotationNavigation/AnnotationNavigation';
import './MobileAnnotationView.scss';

var MobileAnnotationView = (_temp2 = _class = function (_Component) {
  _inherits(MobileAnnotationView, _Component);

  function MobileAnnotationView() {
    var _temp, _this, _ret;

    _classCallCheck(this, MobileAnnotationView);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      current: 0
    }, _this.onNext = function () {
      var annotations = _this.props.annotations;
      var current = _this.state.current;

      if (current >= annotations.length) {
        return null;
      }

      _this.goToAnnotation(current + 1);
    }, _this.onPrevious = function () {
      var current = _this.state.current;

      if (current === 0) {
        return;
      }
      _this.goToAnnotation(current - 1);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  MobileAnnotationView.prototype.componentWillReceiveProps = function componentWillReceiveProps(newProps, newContext) {
    if (newProps.disabled === false && this.props.disabled === true) {
      this.goToAnnotation(this.state.current);
    }
  };

  MobileAnnotationView.prototype.goToAnnotation = function goToAnnotation(index) {
    var annotations = this.props.annotations;


    if (index === 0) {
      this.props.viewport.resetView();
    } else {
      var _annotations$on$selec = annotations[index - 1].on.selector,
          x = _annotations$on$selec.x,
          y = _annotations$on$selec.y,
          width = _annotations$on$selec.width,
          height = _annotations$on$selec.height;

      this.props.viewport.goToRect({
        x: x,
        y: y,
        width: width,
        height: height * 2
      }, this.props.animationFramePadding);
    }

    // navigate to annotation.
    this.setState({
      current: index
    });
  };

  MobileAnnotationView.prototype.renderSplash = function renderSplash() {
    var _this2 = this;

    var _props = this.props,
        bem = _props.bem,
        children = _props.children;

    return React.createElement(
      'div',
      { className: bem.element('inner') },
      children,
      React.createElement(
        'button',
        {
          className: bem.element('button'),
          onClick: function onClick() {
            return _this2.goToAnnotation(1);
          }
        },
        'Start'
      )
    );
  };

  MobileAnnotationView.prototype.renderAnnotation = function renderAnnotation(annotation, next) {
    var current = this.state.current;
    var _props2 = this.props,
        bem = _props2.bem,
        annotations = _props2.annotations,
        disabled = _props2.disabled;


    return React.createElement(
      'div',
      { className: bem.element('annotation-panel').modifiers({ disabled: disabled }) },
      React.createElement(AnnotationDetail, { annotation: annotation }),
      React.createElement(
        'div',
        { className: bem.element('footer') },
        React.createElement(AnnotationNavigation, {
          bem: bem,
          onNext: current >= annotations.length ? null : this.onNext,
          onPrevious: current <= 0 ? null : this.onPrevious
        })
      ),
      React.createElement(
        'div',
        { className: bem.element('void') },
        next ? React.createElement(AnnotationDetail, { annotation: next }) : null
      )
    );
  };

  MobileAnnotationView.prototype.render = function render() {
    var _props3 = this.props,
        annotations = _props3.annotations,
        disabled = _props3.disabled,
        bem = _props3.bem;
    var current = this.state.current;

    var annotation = current === 0 ? null : annotations[current - 1].annotation;
    var next = annotations[current] ? annotations[current].annotation : null;

    return React.createElement(
      'div',
      { className: bem.modifiers({ splash: current === 0, disabled: disabled }) },
      annotation ? this.renderAnnotation(annotation, next) : this.renderSplash()
    );
  };

  return MobileAnnotationView;
}(Component), _class.defaultProps = {
  animationFramePadding: 400
}, _temp2);


export default withBemClass('mobile-annotation-view')(MobileAnnotationView);