var _class, _temp2;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import CanvasDetail from '../CanvasDetail/CanvasDetail';
import { withBemClass, SingleTileSource, OpenSeadragonViewport, FullPageViewport, Responsive } from '@canvas-panel/core';
import './MobileViewer.scss';
import ZoomButtons from '../ZoomButtons/ZoomButtons';
import CanvasNavigation from '../CanvasNavigation/CanvasNavigation';

var ExitFullscreenIcon = function ExitFullscreenIcon(_ref) {
  var className = _ref.className;
  return React.createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      width: '20',
      height: '20',
      viewBox: '0 0 24 24',
      className: className
    },
    React.createElement('path', { d: 'M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z', fill: '#fff' }),
    React.createElement('path', { d: 'M0 0h24v24H0z', fill: 'none' })
  );
};

var InfoIcon = function InfoIcon(_ref2) {
  var onClick = _ref2.onClick,
      className = _ref2.className;
  return React.createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      width: '24',
      height: '24',
      viewBox: '0 0 24 24',
      className: className,
      onClick: onClick
    },
    React.createElement('path', { d: 'M0 0h24v24H0z', fill: 'none' }),
    React.createElement('path', {
      d: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z',
      fill: '#fff'
    })
  );
};

var CloseIcon = function CloseIcon(_ref3) {
  var onClick = _ref3.onClick,
      className = _ref3.className;
  return React.createElement(
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      width: '24',
      height: '24',
      viewBox: '0 0 24 24',
      className: className,
      onClick: onClick
    },
    React.createElement('path', { d: 'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' }),
    React.createElement('path', { d: 'M0 0h24v24H0z', fill: 'none' })
  );
};

var Attribution = function Attribution(_ref4) {
  var bem = _ref4.bem,
      hidden = _ref4.hidden,
      children = _ref4.children;
  return React.createElement(
    'div',
    { className: bem.element('attribution').modifiers({ hidden: hidden }) },
    children
  );
};

var ExitFullscreen = function ExitFullscreen(_ref5) {
  var bem = _ref5.bem,
      hidden = _ref5.hidden,
      onClick = _ref5.onClick;
  return React.createElement(
    'div',
    {
      className: bem.element('exit-fullscreen').modifiers({ hidden: hidden }),
      onClick: onClick
    },
    React.createElement(ExitFullscreenIcon, { className: bem.element('exit-fullscreen-icon') }),
    'Exit slideshow'
  );
};

var InfoButton = function InfoButton(_ref6) {
  var bem = _ref6.bem,
      onClick = _ref6.onClick,
      hidden = _ref6.hidden;
  return React.createElement(
    'div',
    { className: bem.element('info').modifiers({ hidden: hidden }), onClick: onClick },
    React.createElement(InfoIcon, { className: bem.element('info-icon') })
  );
};

var Navigation = function Navigation(_ref7) {
  var bem = _ref7.bem,
      children = _ref7.children;
  return React.createElement(
    'div',
    { className: bem.element('navigation') },
    children
  );
};

var InfoPanel = function InfoPanel(_ref8) {
  var bem = _ref8.bem,
      hidden = _ref8.hidden,
      onClose = _ref8.onClose,
      children = _ref8.children,
      label = _ref8.label;
  return React.createElement(
    'div',
    {
      className: bem.element('info-panel').modifiers({
        hidden: hidden
      }),
      onClick: onClose
    },
    React.createElement(CloseIcon, { className: bem.element('info-panel-close') }),
    React.createElement(
      'h2',
      null,
      label
    ),
    React.createElement(
      'p',
      { className: bem.element('info-panel-body') },
      children
    )
  );
};

var MobileViewer = (_temp2 = _class = function (_Component) {
  _inherits(MobileViewer, _Component);

  function MobileViewer() {
    var _temp, _this, _ret;

    _classCallCheck(this, MobileViewer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = { open: false, constrained: false, offset: 0 }, _this.onConstrain = function (viewer, x, y) {
      var stateToUpdate = {};
      if (_this.props.applyOffset) {
        _this.props.applyOffset(-x);
        stateToUpdate.offset = -x;
      }
      stateToUpdate.constrained = true;
      if (y) {
        _this.applyConstraints(viewer, true);
      }
      _this.setState(stateToUpdate);
    }, _this.onDragStart = function (viewer) {
      if (_this.props.onDragStart) {
        _this.props.onDragStart();
      }
      _this.setState({ dragging: true });
    }, _this.onDragStop = function (viewer) {
      if (_this.props.onDragStop) {
        _this.props.onDragStop();
      }
      _this.setState({ dragging: false });

      if (_this.props.applyOffset) {
        _this.props.applyOffset(0);
      }
      _this.setState({ constrained: false });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  MobileViewer.prototype.applyConstraints = function applyConstraints(viewer, immediately) {
    var bounds = viewer.viewport.getBoundsNoRotate();
    var constrainedBounds = viewer.viewport._applyBoundaryConstraints(bounds);

    constrainedBounds.x = bounds.x;
    if (bounds.y !== constrainedBounds.y) {
      viewer.viewport.fitBounds(constrainedBounds, immediately);
    }
  };

  MobileViewer.prototype.render = function render() {
    var _this2 = this;

    var dragging = this.state.dragging;

    var _props = this.props,
        current = _props.current,
        onDragStart = _props.onDragStart,
        onDragStop = _props.onDragStop,
        onClose = _props.onClose,
        onExitFullscreen = _props.onExitFullscreen,
        onOpen = _props.onOpen,
        isOpen = _props.isOpen,
        bem = _props.bem,
        nextRange = _props.nextRange,
        previousRange = _props.previousRange,
        canvasList = _props.canvasList,
        size = _props.size,
        onZoomIn = _props.onZoomIn,
        onZoomOut = _props.onZoomOut,
        props = _objectWithoutProperties(_props, ['current', 'onDragStart', 'onDragStop', 'onClose', 'onExitFullscreen', 'onOpen', 'isOpen', 'bem', 'nextRange', 'previousRange', 'canvasList', 'size', 'onZoomIn', 'onZoomOut']);

    var canvas = props.canvas,
        index = props.index;


    if (!canvas) {
      return React.createElement('div', null);
    }

    return React.createElement(
      CanvasDetail,
      { key: canvas.id, canvas: canvas },
      function (_ref9) {
        var label = _ref9.label,
            body = _ref9.body,
            attributionLabel = _ref9.attributionLabel,
            attribution = _ref9.attribution;
        return React.createElement(
          'div',
          { className: bem },
          React.createElement(
            'div',
            { className: bem.element('inner') },
            React.createElement(
              SingleTileSource,
              props,
              current ? React.createElement(
                Attribution,
                { bem: bem, hidden: !current || dragging },
                attributionLabel,
                ' ',
                attribution
              ) : React.createElement(React.Fragment, null),
              current ? React.createElement(ExitFullscreen, {
                bem: bem,
                onClick: onExitFullscreen,
                hidden: !current || dragging
              }) : React.createElement(React.Fragment, null),
              current ? React.createElement(InfoButton, {
                bem: bem,
                onClick: onOpen,
                hidden: !current || dragging
              }) : React.createElement(React.Fragment, null),
              React.createElement(
                Navigation,
                { bem: bem },
                current ? React.createElement(
                  'div',
                  {
                    className: bem.element('zoom-controls').modifiers({ hidden: !current || dragging })
                  },
                  React.createElement(ZoomButtons, {
                    right: true,
                    onZoomIn: onZoomIn,
                    onZoomOut: onZoomOut
                  })
                ) : null,
                current ? React.createElement(
                  'div',
                  {
                    className: bem.element('canvas-navigation').modifiers({ hidden: !current || dragging })
                  },
                  React.createElement(CanvasNavigation, {
                    previousRange: previousRange,
                    nextRange: nextRange,
                    size: size,
                    currentIndex: index
                  })
                ) : null
              ),
              React.createElement(
                FullPageViewport,
                {
                  setRef: _this2.props.setViewport,
                  position: 'absolute',
                  interactive: true,
                  style: { height: '100%' },
                  osdOptions: {
                    visibilityRatio: 1,
                    constrainDuringPan: false,
                    showNavigator: false,
                    animationTime: 0.3
                  },
                  onConstrain: _this2.onConstrain
                },
                React.createElement(OpenSeadragonViewport, {
                  useMaxDimensions: true,
                  interactive: true,
                  onDragStart: _this2.onDragStart,
                  onDragStop: _this2.onDragStop,
                  osdOptions: _this2.osdOptions
                })
              )
            )
          ),
          current ? React.createElement(
            InfoPanel,
            {
              bem: bem,
              hidden: dragging === true || !isOpen,
              onClose: onClose,
              label: label
            },
            body
          ) : null
        );
      }
    );
  };

  return MobileViewer;
}(Component), _class.defaultProps = {
  applyOffset: function applyOffset() {
    return null;
  },
  setViewport: function setViewport() {
    return null;
  }
}, _temp2);


export default withBemClass('mobile-viewer')(MobileViewer);