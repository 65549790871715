function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { withBemClass } from '@canvas-panel/core';
import './ProgressIndicator.scss';

var ProgressIndicator = function (_Component) {
  _inherits(ProgressIndicator, _Component);

  function ProgressIndicator() {
    _classCallCheck(this, ProgressIndicator);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  ProgressIndicator.prototype.render = function render() {
    var _props = this.props,
        currentCanvas = _props.currentCanvas,
        totalCanvases = _props.totalCanvases,
        bem = _props.bem;

    var progressPercent = currentCanvas / (totalCanvases - 1) * 100;
    return React.createElement(
      'div',
      { className: bem },
      React.createElement(
        'div',
        { className: bem.element('track') },
        React.createElement('div', {
          className: bem.element('value'),
          style: {
            width: progressPercent + '%'
          }
        })
      )
    );
  };

  return ProgressIndicator;
}(Component);

export default withBemClass('progress-indicator')(ProgressIndicator);