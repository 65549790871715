function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { withBemClass } from '@canvas-panel/core';
import './PagePanel.scss';
import AnnotationNavigation from '../AnnotationNavigation/AnnotationNavigation';

var PagePanel = function (_Component) {
  _inherits(PagePanel, _Component);

  function PagePanel() {
    _classCallCheck(this, PagePanel);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  PagePanel.prototype.render = function render() {
    var _props = this.props,
        style = _props.style,
        bem = _props.bem,
        onPrevious = _props.onPrevious,
        onNext = _props.onNext,
        position = _props.position;

    return React.createElement(
      'div',
      {
        className: bem.modifiers({
          disabled: this.props.disabled,
          top: position === 'top',
          bottom: position === 'bottom'
        }),
        style: style
      },
      React.createElement(
        'div',
        { className: bem.element('inner') },
        this.props.children,
        React.createElement(
          'div',
          { className: bem.element('footer') },
          React.createElement(AnnotationNavigation, {
            bem: bem,
            onNext: onNext,
            onPrevious: onPrevious
          })
        )
      )
    );
  };

  return PagePanel;
}(Component);

export default withBemClass('page-panel')(PagePanel);