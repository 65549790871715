var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Manifest, Fullscreen, RangeNavigationProvider, withBemClass, Responsive } from '@canvas-panel/core';
import MobilePageView from '../MobilePageView/MobilePageView';
import SimpleSlideTransition from '../SimpleSlideTransition/SimpleSlideTransition';
import ProgressIndicator from '../ProgressIndicator/ProgressIndicator';
import Slide from '../Slide/Slide';
import CanvasNavigation from '../CanvasNavigation/CanvasNavigation';

import './SlideShow.scss';

var SlideShow = (_temp2 = _class = function (_Component) {
  _inherits(SlideShow, _Component);

  function SlideShow() {
    var _temp, _this, _ret;

    _classCallCheck(this, SlideShow);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      innerWidth: window.innerWidth
    }, _this.setSize = function () {
      _this.setState({
        innerWidth: window.innerWidth
      });
    }, _this.qualifiesForMobile = function () {
      return _this.state.innerWidth <= _this.props.mobileBreakpoint;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  SlideShow.prototype.componentWillMount = function componentWillMount() {
    window.addEventListener('resize', this.setSize);
  };

  SlideShow.prototype.componentWillUnmount = function componentWillUnmount() {
    window.removeEventListener('resize', this.setSize);
  };

  SlideShow.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        manifestUri = _props.manifestUri,
        jsonLd = _props.jsonLd,
        renderPanel = _props.renderPanel,
        bem = _props.bem;

    return React.createElement(
      'div',
      {
        className: bem.modifiers({
          isMobile: Responsive.md.phone()
        })
      },
      React.createElement(
        Fullscreen,
        null,
        function (_ref) {
          var ref = _ref.ref,
              fullscreenProps = _objectWithoutProperties(_ref, ['ref']);

          return React.createElement(
            Manifest,
            { url: manifestUri, jsonLd: jsonLd },
            React.createElement(
              RangeNavigationProvider,
              null,
              function (rangeProps) {
                var manifest = rangeProps.manifest,
                    canvas = rangeProps.canvas,
                    canvasList = rangeProps.canvasList,
                    currentIndex = rangeProps.currentIndex,
                    previousRange = rangeProps.previousRange,
                    nextRange = rangeProps.nextRange,
                    region = rangeProps.region;

                return React.createElement(
                  'div',
                  { className: bem.element('inner-frame'), ref: ref },
                  _this2.qualifiesForMobile() ? React.createElement(MobilePageView, _extends({
                    manifest: manifest,
                    previousRange: previousRange,
                    nextRange: nextRange,
                    fullscreenProps: fullscreenProps
                  }, rangeProps)) : React.createElement(
                    React.Fragment,
                    null,
                    React.createElement(
                      SimpleSlideTransition,
                      { id: currentIndex },
                      React.createElement(Slide, {
                        fullscreenProps: fullscreenProps,
                        behaviors: canvas.__jsonld.behavior || [],
                        manifest: manifest,
                        canvas: canvas,
                        region: region,
                        renderPanel: renderPanel
                      })
                    ),
                    React.createElement(CanvasNavigation, {
                      previousRange: previousRange,
                      nextRange: nextRange,
                      canvasList: canvasList,
                      currentIndex: currentIndex
                    }),
                    React.createElement(ProgressIndicator, {
                      currentCanvas: currentIndex,
                      totalCanvases: canvasList.length
                    })
                  )
                );
              }
            )
          );
        }
      )
    );
  };

  return SlideShow;
}(Component), _class.defaultProps = {
  mobileBreakpoint: 767
}, _temp2);
SlideShow.propTypes = process.env.NODE_ENV !== "production" ? {
  manifestUri: PropTypes.string,
  jsonLd: PropTypes.object,
  mobileBreakpoint: PropTypes.number
} : {};


export default withBemClass('slideshow')(SlideShow);