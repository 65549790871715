function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { withBemClass } from '@canvas-panel/core';
import './ExploreButton.scss';
import { Responsive } from '@canvas-panel/core';

var ExploreButton = function (_Component) {
  _inherits(ExploreButton, _Component);

  function ExploreButton() {
    _classCallCheck(this, ExploreButton);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  ExploreButton.prototype.render = function render() {
    var _props = this.props,
        bem = _props.bem,
        onClick = _props.onClick,
        interactive = _props.interactive,
        style = _props.style;

    return React.createElement(
      Responsive,
      {
        phoneOnly: function phoneOnly() {
          return React.createElement(
            'button',
            {
              className: bem.modifiers({ interactive: interactive, mobile: true }),
              onClick: onClick,
              style: style
            },
            interactive ? 'Back to tour' : 'Free explore'
          );
        }
      },
      React.createElement(
        'button',
        {
          className: bem.modifiers({ interactive: interactive }),
          onClick: onClick,
          style: style
        },
        interactive ? 'Back to tour' : 'Free explore'
      )
    );
  };

  return ExploreButton;
}(Component);

export default withBemClass('explore-button')(ExploreButton);