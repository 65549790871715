function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { withBemClass } from '@canvas-panel/core';
import './AnnotationNavigation.scss';

var AnnotationNavigation = function (_Component) {
  _inherits(AnnotationNavigation, _Component);

  function AnnotationNavigation() {
    _classCallCheck(this, AnnotationNavigation);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  AnnotationNavigation.prototype.render = function render() {
    var _props = this.props,
        bem = _props.bem,
        onPrevious = _props.onPrevious,
        onNext = _props.onNext;

    return React.createElement(
      'div',
      { className: bem },
      React.createElement(
        'button',
        {
          onClick: onPrevious,
          className: bem.element('previous').modifiers({ disabled: !onPrevious })
        },
        React.createElement(
          'svg',
          {
            xmlns: 'http://www.w3.org/2000/svg',
            viewBox: '0 -256 1792 1792',
            style: {
              height: 10,
              marginRight: 5,
              transform: 'rotate(180deg) translateY(-1px)'
            }
          },
          React.createElement('path', {
            d: 'M1448.288 626.983q0 52-37 91l-652 651q-37 37-90 37t-90-37l-76-75q-37-39-37-91 0-53 37-90l486-486-486-485q-37-39-37-91 0-53 37-90l76-75q36-38 90-38t90 38l652 651q37 37 37 90z',
            fill: 'currentColor'
          })
        ),
        'Prev'
      ),
      React.createElement(
        'button',
        {
          onClick: onNext,
          className: bem.element('next').modifiers({ disabled: !onNext })
        },
        'Next',
        React.createElement(
          'svg',
          {
            xmlns: 'http://www.w3.org/2000/svg',
            viewBox: '0 -256 1792 1792',
            style: {
              marginLeft: 5,
              height: 10,
              transform: 'translateY(1px)'
            }
          },
          React.createElement('path', {
            d: 'M1448.288 626.983q0 52-37 91l-652 651q-37 37-90 37t-90-37l-76-75q-37-39-37-91 0-53 37-90l486-486-486-485q-37-39-37-91 0-53 37-90l76-75q36-38 90-38t90 38l652 651q37 37 37 90z',
            fill: 'currentColor'
          })
        )
      )
    );
  };

  return AnnotationNavigation;
}(Component);

export default withBemClass('annotation-navigation')(AnnotationNavigation);