function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { withBemClass } from '@canvas-panel/core';
import SwappableViewer from '../SwappableViewer/SwappableViewer';
import CanvasDetail from '../CanvasDetail/CanvasDetail';
import './Slide.scss';

var Slide = function (_Component) {
  _inherits(Slide, _Component);

  function Slide() {
    _classCallCheck(this, Slide);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  Slide.prototype.render = function render() {
    var _props = this.props,
        bem = _props.bem,
        behaviors = _props.behaviors,
        manifest = _props.manifest,
        canvas = _props.canvas,
        region = _props.region,
        renderPanel = _props.renderPanel,
        fullscreenProps = _props.fullscreenProps;

    return React.createElement(
      'div',
      {
        className: bem.modifiers(behaviors.reduce(function (acc, next) {
          acc[next] = true;
          return acc;
        }, {}))
      },
      React.createElement(SwappableViewer, {
        fullscreenProps: fullscreenProps,
        isInteractive: fullscreenProps.isFullscreen,
        manifest: manifest,
        canvas: canvas,
        region: region
      }),
      renderPanel ? renderPanel(this.props) : React.createElement(
        CanvasDetail,
        { canvas: canvas },
        function (_ref) {
          var label = _ref.label,
              body = _ref.body,
              attributionLabel = _ref.attributionLabel,
              attribution = _ref.attribution;
          return React.createElement(
            'div',
            { className: bem.element('overlay') },
            React.createElement(
              'div',
              { className: bem.element('overlay-content') },
              label ? React.createElement(
                'h3',
                { className: bem.element('title') },
                label
              ) : null,
              body ? React.createElement(
                'p',
                { className: bem.element('text') },
                body
              ) : null
            ),
            React.createElement(
              'div',
              { className: bem.element('overlay-floating') },
              React.createElement(
                'p',
                { className: bem.element('required-statement') },
                attributionLabel,
                attribution
              )
            )
          );
        }
      )
    );
  };

  return Slide;
}(Component);

export default withBemClass('slide')(Slide);