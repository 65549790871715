function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { LocaleString, withBemClass } from '@canvas-panel/core';
import './CanvasDetail.scss';

var CanvasDetail = function (_Component) {
  _inherits(CanvasDetail, _Component);

  function CanvasDetail() {
    _classCallCheck(this, CanvasDetail);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  CanvasDetail.prototype.getLabel = function getLabel() {
    var label = this.props.canvas.getLabel();
    return !label || label.length === 0 ? null : React.createElement(
      LocaleString,
      null,
      this.props.canvas.getLabel()
    );
  };

  CanvasDetail.prototype.getBody = function getBody() {
    var _props$canvas$__jsonl = this.props.canvas.__jsonld,
        summary = _props$canvas$__jsonl.summary,
        description = _props$canvas$__jsonl.description;


    if (summary) {
      return React.createElement(
        LocaleString,
        null,
        summary
      );
    }

    if (description) {
      return React.createElement(
        LocaleString,
        null,
        description
      );
    }

    return null;
  };

  CanvasDetail.prototype.getAttribution = function getAttribution() {
    var _props$canvas$__jsonl2 = this.props.canvas.__jsonld,
        attribution = _props$canvas$__jsonl2.attribution,
        requiredStatement = _props$canvas$__jsonl2.requiredStatement;


    if (attribution) {
      return React.createElement(
        LocaleString,
        null,
        attribution
      );
    }
    if (requiredStatement) {
      return React.createElement(
        LocaleString,
        null,
        requiredStatement.value
      );
    }
  };

  CanvasDetail.prototype.getAttributionLabel = function getAttributionLabel() {
    var requiredStatement = this.props.canvas.__jsonld.requiredStatement;

    if (requiredStatement) {
      return React.createElement(
        LocaleString,
        null,
        requiredStatement.label
      );
    }

    return 'Attribution';
  };

  CanvasDetail.prototype.render = function render() {
    var _props = this.props,
        canvas = _props.canvas,
        children = _props.children;


    return children({
      canvas: canvas,
      label: this.getLabel(),
      body: this.getBody(),
      attributionLabel: this.getAttributionLabel(),
      attribution: this.getAttribution()
    });
  };

  return CanvasDetail;
}(Component);

export default CanvasDetail;