var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _class, _temp2;

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { functionOrMapChildren, withBemClass } from '@canvas-panel/core';
import './Container.scss';
import getCurrentScrollY from '../../utils/getCurrentScrollY';

var Container = (_temp2 = _class = function (_Component) {
  _inherits(Container, _Component);

  function Container() {
    var _temp, _this, _ret;

    _classCallCheck(this, Container);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.lastScrollY = -1, _this.scheduledAnimationFrame = false, _this.state = { current: 0 }, _this.handleScrollThrottled = function () {
      // Store the scroll value for later.
      _this.lastScrollY = getCurrentScrollY(_this.props.getContainer());

      // Prevent multiple rAF callbacks.
      if (_this.scheduledAnimationFrame) {
        return;
      }

      _this.scheduledAnimationFrame = true;
      requestAnimationFrame(_this.handleScroll);
    }, _this.handleScroll = function () {
      _this.scheduledAnimationFrame = false;
      var currentAccurate = _this.lastScrollY / _this.props.windowHeight;
      // Double tilde quicker than Math.floor, useful for scroll events.
      var current = ~~currentAccurate;
      _this.props.updateIndividual(currentAccurate);
      if (current !== _this.state.current) {
        _this.setState(function () {
          return {
            current: current
          };
        });
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Container.prototype.componentWillMount = function componentWillMount() {
    var _this2 = this;

    this.setState(function () {
      return {
        // Double tilde quicker than Math.floor, useful for scroll events.
        current: ~~(_this2.lastScrollY / _this2.props.windowHeight)
      };
    });
  };

  Container.prototype.componentWillReceiveProps = function componentWillReceiveProps(newProps, nextContext) {
    var _this3 = this;

    if (newProps.windowHeight !== this.props.windowHeight) {
      this.setState(function () {
        return {
          // Double tilde quicker than Math.floor, useful for scroll events.
          current: ~~(_this3.lastScrollY / newProps.windowHeight)
        };
      });
    }
  };

  Container.prototype.componentDidMount = function componentDidMount() {
    var _this4 = this;

    this.props.getContainer().addEventListener('scroll', this.handleScrollThrottled);

    // Set timeout, configured with load delay setting.
    setTimeout(function () {
      _this4.props.updateIndividual(getCurrentScrollY(_this4.props.getContainer()) / _this4.props.windowHeight);
    }, this.props.onLoadDelay);
  };

  Container.prototype.componentWillUnmount = function componentWillUnmount() {
    var container = this.props.getContainer();
    if (container) {
      container.removeEventListener('scroll', this.handleScrollThrottled);
    }
  };

  Container.prototype.render = function render() {
    var _props = this.props,
        children = _props.children,
        bem = _props.bem,
        style = _props.style,
        disabled = _props.disabled,
        props = _objectWithoutProperties(_props, ['children', 'bem', 'style', 'disabled']);

    var current = this.state.current;


    return React.createElement(
      'div',
      { className: bem.modifiers({ disabled: disabled }), style: style },
      functionOrMapChildren(children, _extends({}, props, { current: current }))
    );
  };

  return Container;
}(Component), _class.defaultProps = {
  windowHeight: window.innerHeight,
  onLoadDelay: 500
}, _temp2);


export default withBemClass('full-page-container')(Container);