function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { withBemClass } from '@canvas-panel/core';
import './ScrollDownIcon.scss';
import getCurrentScrollY from '../../utils/getCurrentScrollY';

var ScrollDownIcon = function (_Component) {
  _inherits(ScrollDownIcon, _Component);

  function ScrollDownIcon() {
    var _temp, _this, _ret;

    _classCallCheck(this, ScrollDownIcon);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = { hidden: false }, _this.onScroll = function () {
      var container = _this.props.getContainer();

      if (container && getCurrentScrollY(container) > 100) {
        _this.setState({ hidden: true });
        container.removeEventListener('scroll', _this.onScroll);
      }
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  ScrollDownIcon.prototype.componentDidMount = function componentDidMount() {
    var container = this.props.getContainer();
    if (container) {
      container.addEventListener('scroll', this.onScroll);
      if (getCurrentScrollY(this.props.getContainer()) > 100) {
        this.setState({ hidden: true });
        container.removeEventListener('scroll', this.onScroll);
      }
    }
  };

  ScrollDownIcon.prototype.render = function render() {
    var hidden = this.state.hidden;
    var bem = this.props.bem;

    return React.createElement('div', { className: bem.modifiers({ hidden: hidden }) });
  };

  return ScrollDownIcon;
}(Component);

export default withBemClass('scroll-down-icon')(ScrollDownIcon);