var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var TapDetector = function () {
  function TapDetector($el, options) {
    var _this = this;

    _classCallCheck(this, TapDetector);

    this.onTaps = [];

    this.onTap = function (func) {
      _this.onTaps.push(func);
    };

    this.handleTap = function () {
      _this.onTaps.forEach(function (fn) {
        return fn();
      });
    };

    this.touchData = {
      clientX: 0,
      clientY: 0,
      tap: true,
      stopped: false,
      noDoubleTap: null,
      timeout: null,
      doubleTapTimeout: null
    };

    this.handleTouchStart = function (e) {
      var _e$touches$ = e.touches[0],
          clientX = _e$touches$.clientX,
          clientY = _e$touches$.clientY;

      _this.touchData.clientX = clientX;
      _this.touchData.clientY = clientY;
      _this.touchData.timeout = setTimeout(function () {
        _this.touchData.stopped = true;
      }, _this.options.tapTimeout);
      if (_this.touchData.noDoubleTap) {
        _this.touchData.noDoubleTap = false;
      }
    };

    this.handleTouchMove = function (e) {
      if (_this.touchData.tap) {
        var _e$touches$2 = e.touches[0],
            clientX = _e$touches$2.clientX,
            clientY = _e$touches$2.clientY;

        var distX = Math.abs(clientX - _this.touchData.clientX);
        var distY = Math.abs(clientY - _this.touchData.clientY);
        if (distY >= _this.options.tapRadius || distX >= _this.options.tapRadius) {
          _this.touchData.tap = false;
        }
      }
    };

    this.handleTouchEnd = function () {
      clearTimeout(_this.touchData.timeout);
      clearTimeout(_this.touchData.doubleTapTimeout);

      if (_this.touchData.tap && _this.touchData.stopped === false && _this.touchData.noDoubleTap === null) {
        _this.touchData.noDoubleTap = true;
        _this.touchData.doubleTapTimeout = setTimeout(function () {
          if (_this.touchData.noDoubleTap) {
            _this.handleTap();
          } else {
            // this.onDoubleTap();
          }
          _this.touchData.noDoubleTap = null;
        }, _this.options.doubleTapTimeout);
      } else {
        _this.touchData.noDoubleTap = null;
      }
      _this.touchData = _extends({}, _this.touchData, {
        clientX: 0,
        clientY: 0,
        tap: true,
        stopped: false,
        timeout: null
      });
    };

    this.$el = $el;
    this.$el.addEventListener('touchstart', this.handleTouchStart);
    this.$el.addEventListener('touchend', this.handleTouchEnd);
    this.$el.addEventListener('touchmove', this.handleTouchMove);
    this.options = _extends({
      doubleTapTimeout: 150,
      tapTimeout: 500,
      tapRadius: 50
    }, options);
  }

  TapDetector.prototype.unbind = function unbind() {
    this.$el.removeEventListener('touchstart', this.handleTouchStart);
    this.$el.removeEventListener('touchend', this.handleTouchEnd);
    this.$el.removeEventListener('touchmove', this.handleTouchMove);
  };

  return TapDetector;
}();

export default TapDetector;