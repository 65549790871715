var _class, _temp2, _initialiseProps;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Manifest, CanvasProvider, Viewport, SingleTileSource, OpenSeadragonViewer, OpenSeadragonViewport, AnnotationDetail, AnnotationCanvasRepresentation, Fullscreen, FullPageViewport, Bem, withBemClass } from '@canvas-panel/core';

var defaultConfiguration = {
  cssClassPrefix: '',
  manifest: null,
  jsonLdManifest: null,
  cssClassMap: {},
  animationSpeed: 500,
  animationSpeedMap: {},
  fitContainer: false,
  // fixedSize: null, // { x, y }
  height: 500,
  annotationMargin: 600,
  width: 1200,
  mobileHeight: window.innerWidth,
  renderAnnotation: null,
  allowFullScreen: true,
  events: {},
  osdOptions: {},
  dispatch: function dispatch() {
    return null;
  },
  disableMouseEventsOnMobile: true,
  mobileBreakpoint: 639,
  growthStyle: 'fixed',
  closeText: '×',
  relativeContainer: true,
  clickToClose: true
};

var AdaptiveViewport = function AdaptiveViewport(_ref) {
  var fullViewport = _ref.fullViewport,
      isFullscreen = _ref.isFullscreen,
      maxWidth = _ref.maxWidth,
      maxHeight = _ref.maxHeight,
      props = _objectWithoutProperties(_ref, ['fullViewport', 'isFullscreen', 'maxWidth', 'maxHeight']);

  if (fullViewport || isFullscreen) {
    return React.createElement(
      FullPageViewport,
      _extends({ position: 'absolute', interactive: true }, props),
      props.children
    );
  }
  return React.createElement(
    Viewport,
    _extends({ maxWidth: maxWidth, maxHeight: maxHeight }, props),
    props.children
  );
};

var FullScreenToggle = withBemClass('fullscreen-toggle')(function (_ref2) {
  var bem = _ref2.bem,
      toggleFullscreen = _ref2.toggleFullscreen,
      isFullscreen = _ref2.isFullscreen,
      mobileBreakpoint = _ref2.mobileBreakpoint;
  return React.createElement(
    'button',
    {
      className: bem.modifiers({
        'is-fullscreen': isFullscreen,
        'is-mobile': window.innerWidth < mobileBreakpoint
      }),
      onClick: toggleFullscreen
    },
    isFullscreen ? 'exit' : window.innerWidth < mobileBreakpoint ? 'explore in fullscreen' : 'fullscreen'
  );
});

var FullscreenCover = withBemClass('fullscreen-cover')(function (_ref3) {
  var toggleFullscreen = _ref3.toggleFullscreen,
      bem = _ref3.bem;
  return React.createElement('div', { className: bem, onClick: toggleFullscreen });
});

var PatchworkPlugin = (_temp2 = _class = function (_Component) {
  _inherits(PatchworkPlugin, _Component);

  function PatchworkPlugin() {
    var _temp, _this, _ret;

    _classCallCheck(this, PatchworkPlugin);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _initialiseProps.call(_this), _temp), _possibleConstructorReturn(_this, _ret);
  }

  PatchworkPlugin.prototype.getAnimationSpeed = function getAnimationSpeed(name) {
    if (this.props.animationSpeedMap[name]) {
      return this.props.animationSpeedMap[name] / 1000;
    }
    return this.props.animationSpeed / 1000;
  };

  PatchworkPlugin.prototype.getClasses = function getClasses(_ref4) {
    var fullscreenEnabled = _ref4.fullscreenEnabled,
        isFullscreen = _ref4.isFullscreen,
        isMobileFullscreen = _ref4.isMobileFullscreen;

    var classNames = [];
    if (fullscreenEnabled) {
      classNames.push('fullscreen-available');
    }
    if (isFullscreen) {
      classNames.push('fullscreen-native-enabled');
    }
    if (isMobileFullscreen) {
      classNames.push('fullscreen-mobile-enabled');
    }
    if (isFullscreen || isMobileFullscreen) {
      classNames.push('fullscreen-active');
    }

    return classNames;
  };

  PatchworkPlugin.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        manifest = _props.manifest,
        jsonLdManifest = _props.jsonLdManifest,
        canvas = _props.canvas,
        mobileHeight = _props.mobileHeight,
        desktopHeight = _props.height,
        width = _props.width,
        osdOptions = _props.osdOptions,
        cssClassMap = _props.cssClassMap,
        cssClassPrefix = _props.cssClassPrefix,
        growthStyle = _props.growthStyle,
        closeText = _props.closeText,
        relativeContainer = _props.relativeContainer,
        fitContainer = _props.fitContainer,
        mobileBreakpoint = _props.mobileBreakpoint;

    var height = window.innerWidth < mobileBreakpoint ? mobileHeight : desktopHeight;

    var state = this.state;

    return React.createElement(
      'div',
      {
        ref: this.setRef,
        style: {
          position: relativeContainer ? 'relative' : null
        }
      },
      React.createElement(
        Fullscreen,
        null,
        function (_ref5) {
          var isFullscreen = _ref5.isFullscreen,
              fullscreenEnabled = _ref5.fullscreenEnabled,
              toggleFullscreen = _ref5.toggleFullscreen,
              ref = _ref5.ref;
          return React.createElement(
            'div',
            {
              ref: ref,
              className: _this2.getClasses({
                fullscreenEnabled: fullscreenEnabled,
                isFullscreen: isFullscreen,
                isMobileFullscreen: _this2.state.isMobileFullscreen
              }).join(' '),
              style: { zIndex: _this2.state.isMobileFullscreen ? '10000' : null }
            },
            React.createElement(
              Bem,
              { cssClassMap: cssClassMap, prefix: cssClassPrefix },
              React.createElement(
                Manifest,
                { url: manifest, jsonLd: jsonLdManifest },
                React.createElement(
                  CanvasProvider,
                  { startCanvas: canvas },
                  React.createElement(
                    AdaptiveViewport,
                    {
                      isFullscreen: fullscreenEnabled ? isFullscreen : false,
                      fullViewport: fitContainer,
                      maxWidth: isFullscreen || state.isMobileFullscreen ? window.innerWidth : width,
                      maxHeight: isFullscreen || state.isMobileFullscreen ? window.innerHeight : height,
                      setRef: _this2.setViewport
                    },
                    React.createElement(
                      SingleTileSource,
                      { viewportController: true },
                      React.createElement(
                        OpenSeadragonViewport,
                        null,
                        React.createElement(OpenSeadragonViewer, {
                          useMaxDimensions: true,
                          osdOptions: _extends({
                            visibilityRatio: 1,
                            constrainDuringPan: true,
                            showNavigator: false,
                            immediateRender: false
                          }, osdOptions)
                        })
                      )
                    ),
                    React.createElement(AnnotationCanvasRepresentation, {
                      ratio: 0.1,
                      ratioFromMaxWidth: 1000,
                      growthStyle: growthStyle,
                      bemModifiers: function bemModifiers(annotation) {
                        return {
                          selected: state.annotation ? state.annotation.id === annotation.id : null
                        };
                      },
                      onClickAnnotation: _this2.onClickAnnotation
                    }),
                    fullscreenEnabled === false && state.isMobileFullscreen === false ? React.createElement(FullscreenCover, {
                      'data-static': true,
                      toggleFullscreen: _this2.toggleMobileFullscreen
                    }) : null,
                    React.createElement(FullScreenToggle, {
                      'data-static': true,
                      isFullscreen: fullscreenEnabled ? isFullscreen : state.isMobileFullscreen,
                      toggleFullscreen: fullscreenEnabled ? toggleFullscreen : _this2.toggleMobileFullscreen,
                      mobileBreakpoint: mobileBreakpoint
                    }),
                    state.annotation ? React.createElement(AnnotationDetail, {
                      'data-static': true,
                      closeText: closeText,
                      annotation: state.annotation,
                      onClose: _this2.onClose
                    }) : null
                  )
                )
              )
            )
          );
        }
      )
    );
  };

  return PatchworkPlugin;
}(Component), _class.defaultProps = defaultConfiguration, _initialiseProps = function _initialiseProps() {
  var _this3 = this;

  this.viewport = null;
  this.animationSpeed = 1;
  this.state = { annotation: null, isMobileFullscreen: false };

  this.setViewport = function (viewport) {
    return _this3.viewport = viewport;
  };

  this.onClickAnnotation = function (annotation, bounds) {
    var clickToClose = _this3.props.clickToClose;

    _this3.dispatch('onClickAnnotation', { annotation: annotation, bounds: bounds });

    if (clickToClose && _this3.state.annotation && _this3.state.annotation.id === annotation.id) {
      return _this3.onClose();
    }

    _this3.setState({ annotation: annotation });
    _this3.viewport.goToRect(bounds, _this3.props.annotationMargin, _this3.getAnimationSpeed('onClick'));
  };

  this.toggleMobileFullscreen = function () {
    if (_this3.ref.scrollIntoView) {
      _this3.ref.scrollIntoView();
    }
    if (_this3.state.isMobileFullscreen) {
      document.body.classList.remove('cvp-mobile-fullscreen');
      document.removeEventListener('scroll', _this3.handleOnScroll);
    } else {
      document.body.classList.add('cvp-mobile-fullscreen');
      // Small timeout to allow the scrollIntoView.
      setTimeout(function () {
        return document.addEventListener('scroll', _this3.handleOnScroll);
      }, 100);
    }
    _this3.setState({ isMobileFullscreen: !_this3.state.isMobileFullscreen });
  };

  this.handleOnScroll = function () {
    _this3.setState({ isMobileFullscreen: false });
    document.removeEventListener('scroll', _this3.handleOnScroll);
  };

  this.onClose = function () {
    _this3.dispatch('onClose', { annotation: _this3.state.annotation });
    _this3.setState({ annotation: null });
    _this3.viewport.resetView(_this3.getAnimationSpeed('onClose'));
  };

  this.dispatch = function (name, args) {
    if (_this3.props.dispatch) {
      _this3.props.dispatch(name, args, _this3.viewport);
    }
    if (_this3.props.events[name]) {
      _this3.props.events[name](args, _this3.viewport);
    }
  };

  this.setRef = function (ref) {
    return _this3.ref = ref;
  };
}, _temp2);
PatchworkPlugin.propTypes = process.env.NODE_ENV !== "production" ? {} : {};


function create(el, userConfiguration) {
  if (!el) {
    console.error('singleCanvasAnnotationDetailViewer:\n      You must provide an \'el\' property in the configuration pointing\n      to the DOM element you want the viewer to be mounted at.\n    ');
    return;
  }

  if (!userConfiguration.manifest && !userConfiguration.jsonLdManifest) {
    console.error('singleCanvasAnnotationDetailViewer:\n      You must provide a URL pointing to a IIIF manifest, by default the first\n      canvas will be used, you can pass in a \'canvas\' to control which canvas\n      should be displayed.\n    ');
    return;
  }

  var config = Object.assign({}, defaultConfiguration, userConfiguration);

  ReactDOM.render(React.createElement(PatchworkPlugin, config), el);
}

var help = function help() {
  console.info('\n  singleCanvasAnnotationDetailViewer\n  ==================================\n\n  In order to get set up using single canvas annotation detail viewer you must\n  provide at least 2 configuration options:\n   - el: this is the HTML element to target\n   - manifest: this is a link to a IIIF manifest.\n\n   By default the first canvas in the IIIF manifest will be used. You can\n   provider a canvas configuration value to change this (index).\n\n   A basic configuration might look like:\n\n   singleCanvasAnnotationDetailViewer.create(document.getElementById(\'myViewer\'), {\n      manifest: \'http:// ... /\',\n   });\n\n   You can see the full set of default configuration values printed below in the\n   console.\n  ');
  console.log(defaultConfiguration);
};

export { create, help, PatchworkPlugin };