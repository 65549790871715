function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { withBemClass } from '@canvas-panel/core';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './SimpleSlideTransition.scss';

var SimpleSlideTransition = function (_Component) {
  _inherits(SimpleSlideTransition, _Component);

  function SimpleSlideTransition() {
    var _temp, _this, _ret;

    _classCallCheck(this, SimpleSlideTransition);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.scroll = window.scrollY, _this.setScroll = function () {
      window.scrollTo(0, _this.scroll);
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  SimpleSlideTransition.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.id !== this.props.id) {
      this.scroll = window.scrollY;
    }
  };

  SimpleSlideTransition.prototype.render = function render() {
    var _props = this.props,
        children = _props.children,
        id = _props.id,
        bem = _props.bem,
        _props$timeout = _props.timeout,
        timeout = _props$timeout === undefined ? 500 : _props$timeout;


    return React.createElement(
      TransitionGroup,
      { className: bem, onExiting: this.setScroll },
      React.createElement(
        CSSTransition,
        {
          key: id,
          timeout: timeout,
          classNames: 'fade',
          onExiting: this.setScroll
        },
        React.createElement(
          'div',
          { style: { height: '100%', width: '100%' } },
          children
        )
      )
    );
  };

  return SimpleSlideTransition;
}(Component);

export default withBemClass('slide-transitions')(SimpleSlideTransition);