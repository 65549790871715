function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { withBemClass, StaticImageViewport } from '@canvas-panel/core';
import CanvasDetail from '../CanvasDetail/CanvasDetail';
import './MobilePageView.scss';
import TapDetector from '../TapDetector/TapDetector';
import MobileViewer from '../MobileViewer/MobileViewer';
import FullscreenButton from '../FullscreenButton/FullscreenButton';
import PeekComponent from '../PeekComponent/PeekComponent';
import ZoomButtons from '../ZoomButtons/ZoomButtons';

var MobilePageView = function (_Component) {
  _inherits(MobilePageView, _Component);

  function MobilePageView() {
    var _temp, _this, _ret;

    _classCallCheck(this, MobilePageView);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      isFullscreen: false,
      currentCanvas: null,
      offset: 0,
      down: false,
      open: false
    }, _this.onDragStart = function () {
      _this.setState({ down: true });
    }, _this.onDragStop = function () {
      _this.setState({ down: false });
    }, _this.nextRange = function () {
      _this.viewport.viewer.viewer.viewport.applyConstraints(true);
      _this.props.nextRange();
    }, _this.previousRange = function () {
      _this.viewport.viewer.viewer.viewport.applyConstraints(true);
      _this.props.previousRange();
    }, _this.touchDetector = null, _this.setViewport = function (viewport) {
      if (_this.touchDetector) {
        _this.touchDetector.unbind();
      }
      _this.touchDetector = new TapDetector(viewport.viewer.viewer.canvas);
      _this.touchDetector.onTap(_this.onTap);
      _this.viewport = viewport;
    }, _this.onTap = function () {
      _this.setState(function (s) {
        return { open: !s.open };
      });
    }, _this.applyOffset = function (offset) {
      _this.setState({ offset: offset });
    }, _this.onOpen = function () {
      _this.setState({ open: true });
    }, _this.onClose = function () {
      _this.setState({ open: false });
    }, _this.onExitFullscreen = function () {
      _this.setState({ isFullscreen: false });
    }, _this.onEnterFullscreen = function (canvasIndex) {
      return function () {
        _this.props.goToRange(canvasIndex);
        _this.setState({ isFullscreen: true });
      };
    }, _this.zoomOut = function () {
      _this.viewport.zoomOut();
    }, _this.zoomIn = function () {
      _this.viewport.zoomIn();
    }, _this.ensureActiveItemVisible = function () {
      if (_this.activeItem) {
        _this.activeItem.scrollIntoView();
      }
    }, _this.setActiveRef = function (element) {
      _this.activeItem = element;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  MobilePageView.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
    if (nextProps.currentIndex !== this.props.currentIndex) {
      this.setState({ offset: 0, open: false });
    }
  };

  MobilePageView.prototype.componentDidUpdate = function componentDidUpdate(prevProps) {
    // only scroll into view if the active item changed last render
    if (!this.state.isFullscreen) {
      this.ensureActiveItemVisible();
    }
  };

  MobilePageView.prototype.render = function render() {
    var _this2 = this;

    var _state = this.state,
        isFullscreen = _state.isFullscreen,
        offset = _state.offset,
        down = _state.down,
        open = _state.open;
    var _props = this.props,
        currentIndex = _props.currentIndex,
        bem = _props.bem,
        manifest = _props.manifest;


    if (isFullscreen) {
      var _props2 = this.props,
          canvas = _props2.canvas,
          nextRange = _props2.nextRange,
          previousRange = _props2.previousRange,
          getNextRange = _props2.getNextRange,
          getPreviousRange = _props2.getPreviousRange;


      var size = manifest.getSequenceByIndex(0).getCanvases().length;

      return React.createElement(
        PeekComponent,
        {
          down: down,
          customOffset: offset,
          onNext: this.nextRange,
          onPrevious: this.previousRange,
          size: size,
          renderLeft: function renderLeft() {
            return React.createElement(MobileViewer, { manifest: manifest, canvas: getPreviousRange() });
          },
          renderRight: function renderRight() {
            return React.createElement(MobileViewer, { manifest: manifest, canvas: getNextRange() });
          },
          index: currentIndex
        },
        React.createElement(MobileViewer, {
          current: true,
          setViewport: this.setViewport,
          manifest: manifest,
          canvas: canvas,
          onDragStart: this.onDragStart,
          onDragStop: this.onDragStop,
          applyOffset: this.applyOffset,
          onZoomOut: this.zoomOut,
          canvasList: manifest.getSequenceByIndex(0).getCanvases(),
          onZoomIn: this.zoomIn,
          onOpen: this.onOpen,
          onClose: this.onClose,
          onExitFullscreen: this.onExitFullscreen,
          isOpen: open,
          size: size,
          index: currentIndex,
          nextRange: nextRange,
          previousRange: previousRange
        })
      );
    }

    return React.createElement(
      'div',
      { className: bem },
      manifest.getSequenceByIndex(0).getCanvases().map(function (canvas, canvasIndex) {
        return React.createElement(
          CanvasDetail,
          {
            key: canvas ? canvas.id : canvasIndex,
            canvas: canvas
          },
          function (_ref) {
            var label = _ref.label,
                body = _ref.body,
                attributionLabel = _ref.attributionLabel,
                attribution = _ref.attribution;
            return React.createElement(
              'div',
              {
                ref: canvasIndex === currentIndex ? _this2.setActiveRef : null,
                className: bem.element('canvas')
              },
              React.createElement(
                StaticImageViewport,
                {
                  className: bem.element('canvas-image'),
                  manifest: manifest,
                  canvas: canvas,
                  maxHeight: 200,
                  maxWidth: 200
                },
                React.createElement(FullscreenButton, {
                  fullscreenEnabled: true,
                  isFullscreen: isFullscreen,
                  goFullscreen: _this2.onEnterFullscreen(canvasIndex),
                  exitFullscreen: _this2.onExitFullscreen
                }),
                React.createElement(
                  'div',
                  { className: bem.element('attribution') },
                  attributionLabel,
                  ' ',
                  attribution
                )
              ),
              React.createElement(
                'div',
                { className: bem.element('metadata') },
                React.createElement(
                  'div',
                  { className: bem.element('detail') },
                  React.createElement(
                    'h3',
                    { className: bem.element('detail-label') },
                    label
                  ),
                  React.createElement(
                    'p',
                    { className: bem.element('detail-body') },
                    body
                  )
                )
              )
            );
          }
        );
      })
    );
  };

  return MobilePageView;
}(Component);

export default withBemClass('mobile-page-view')(MobilePageView);