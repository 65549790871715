function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { withBemClass } from '@canvas-panel/core';
import Viewer from '../Viewer/Viewer';
import './PopOutViewer.scss';

var PopOutViewer = function (_Component) {
  _inherits(PopOutViewer, _Component);

  function PopOutViewer() {
    var _temp, _this, _ret;

    _classCallCheck(this, PopOutViewer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = { open: false }, _this.inner = React.createRef(), _this.close = function () {
      document.body.style = 'pointer-events: visible';
      _this.setState({ open: false });
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  PopOutViewer.prototype.componentDidMount = function componentDidMount() {
    this.attachEvents(this.inner);
  };

  PopOutViewer.prototype.attachEvents = function attachEvents($el) {
    var _this2 = this;

    var $open = $el.querySelector('[data-open-viewer]');
    if ($open) {
      $open.addEventListener('click', function () {
        document.body.style = 'pointer-events: none';
        _this2.setState({
          open: true
        });
      });
    }
  };

  PopOutViewer.prototype.render = function render() {
    var _this3 = this;

    var open = this.state.open;

    var _props = this.props,
        innerHtml = _props.innerHtml,
        bem = _props.bem,
        props = _objectWithoutProperties(_props, ['innerHtml', 'bem']);

    return React.createElement(
      'div',
      { className: bem },
      open ? React.createElement(
        'button',
        { className: bem.element('close'), onClick: this.close },
        'Exit'
      ) : null,
      open ? React.createElement(Viewer, props) : null,
      React.createElement('div', {
        className: bem.element('preview'),
        ref: function ref(_ref) {
          return _this3.inner = _ref;
        },
        dangerouslySetInnerHTML: { __html: innerHtml }
      })
    );
  };

  return PopOutViewer;
}(Component);

export default withBemClass('pop-out-viewer')(PopOutViewer);