var _class, _temp2;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import BezierEasing from 'bezier-easing';
import PagePanel from '../PagePanel/PagePanel';
import { AnnotationDetail } from '@canvas-panel/core';

var AnnotationListView = (_temp2 = _class = function (_Component) {
  _inherits(AnnotationListView, _Component);

  function AnnotationListView() {
    var _temp, _this, _ret;

    _classCallCheck(this, AnnotationListView);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.setTweenFromAnnotations = function (_ref) {
      var annotations = _ref.annotations,
          width = _ref.width,
          height = _ref.height;

      _this.tween = annotations.reduce(function (_ref2, next) {
        var prev = _ref2.prev,
            _ref2$list = _ref2.list,
            list = _ref2$list === undefined ? [] : _ref2$list;

        return {
          prev: next.on.selector,
          list: [].concat(list, [{ from: prev, to: next.on.selector }])
        };
      }, {
        prev: {
          x: 0,
          y: 0,
          width: width,
          height: height
        }
      });
    }, _this.update = function (n) {
      var t = _this.tween.list[~~n];

      if (t) {
        var ease = _this.ease(n - ~~n);
        _this.props.viewport.goToRect({
          x: t.from.x + (t.to.x - t.from.x) * ease,
          y: t.from.y + (t.to.y - t.from.y) * ease,
          width: t.from.width + (t.to.width - t.from.width) * ease,
          height: t.from.height + (t.to.height - t.from.height) * ease
        }, _this.props.animationFramePadding, 1);
      }
    }, _this.onNext = function (annotation, key) {
      return _this.props.onNext ? function () {
        return _this.props.onNext(annotation, key);
      } : null;
    }, _this.onPrevious = function (annotation, key) {
      return _this.props.onPrevious ? function () {
        return _this.props.onPrevious(annotation, key);
      } : null;
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  AnnotationListView.prototype.componentWillMount = function componentWillMount() {
    this.props.setUpdater(this.update);
    this.updateView(this.props);
    this.updateEasing(this.props.bezierEasing);
    this.setTweenFromAnnotations(this.props);
  };

  AnnotationListView.prototype.updateEasing = function updateEasing(_ref3) {
    var a = _ref3[0],
        b = _ref3[1],
        c = _ref3[2],
        d = _ref3[3];

    this.ease = BezierEasing(a, b, c, d);
  };

  AnnotationListView.prototype.componentWillReceiveProps = function componentWillReceiveProps(newProps, newContext) {
    if (newProps.viewport && newProps.current !== this.props.current) {
      this.updateView(newProps);
    }
    if (newProps.bezierEasing !== this.props.bezierEasing) {
      this.updateEasing(newProps.bezierEasing);
    }
  };

  AnnotationListView.prototype.updateView = function updateView(newProps) {
    // if (newProps.current < newProps.offset) {
    //   newProps.viewport.resetView(this.props.animationSpeed);
    // }
  };

  AnnotationListView.prototype.render = function render() {
    var _this2 = this;

    var _props = this.props,
        annotations = _props.annotations,
        disabled = _props.disabled;


    return (annotations || []).map(function (_ref4, key) {
      var annotation = _ref4.annotation,
          on = _ref4.on;
      return React.createElement(
        PagePanel,
        {
          position: _this2.props.annotationPosition,
          disabled: disabled,
          key: key,
          onNext: annotations.length - 1 > key ? _this2.onNext(annotations[key + 1], key + 1) : null,
          onPrevious: key > 0 ? _this2.onPrevious(annotations[key - 1], key - 1) : null
        },
        React.createElement(AnnotationDetail, { annotation: annotation })
      );
    });
  };

  return AnnotationListView;
}(Component), _class.defaultProps = {
  offset: 0,
  animationFramePadding: 600,
  animationSpeed: 1, // seconds
  bezierEasing: [0.6, 0.02, 0.0, 0.75],
  annotationPosition: 'top'
}, _temp2);


export default AnnotationListView;