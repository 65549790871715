function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { withBemClass } from '@canvas-panel/core';
import './ZoomButtons.scss';

var ZoomButtons = function (_Component) {
  _inherits(ZoomButtons, _Component);

  function ZoomButtons() {
    _classCallCheck(this, ZoomButtons);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  ZoomButtons.prototype.render = function render() {
    var _props = this.props,
        bem = _props.bem,
        onZoomIn = _props.onZoomIn,
        onZoomOut = _props.onZoomOut,
        right = _props.right;

    return React.createElement(
      'div',
      { className: bem.modifiers({ right: right }) },
      React.createElement(
        'button',
        {
          onClick: onZoomIn,
          className: bem.element('button').modifiers({
            in: true,
            disabled: !onZoomIn
          })
        },
        React.createElement(
          'svg',
          {
            viewBox: '0 0 400 400',
            width: '32',
            height: '32',
            xmlns: 'http://www.w3.org/2000/svg'
          },
          React.createElement('path', { fill: 'none', d: 'M-1-1h582v402H-1z' }),
          React.createElement(
            'g',
            null,
            React.createElement('ellipse', { ry: '200', rx: '200', cy: '200.413', cx: '200', fill: '#EAEAEA' }),
            React.createElement('ellipse', { rx: '100', ry: '100', cy: '36', cx: '943.5', fill: '#EAEAEA' }),
            React.createElement('path', {
              d: 'M100 200h200M200 100v200',
              fill: 'none',
              stroke: '#000',
              strokeWidth: '20'
            })
          )
        )
      ),
      React.createElement(
        'button',
        {
          onClick: onZoomOut,
          className: bem.element('button').modifiers({
            out: true,
            disabled: !onZoomOut
          })
        },
        React.createElement(
          'svg',
          {
            viewBox: '0 0 400 400',
            width: '32',
            height: '32',
            xmlns: 'http://www.w3.org/2000/svg'
          },
          React.createElement('path', { fill: 'none', d: 'M-1-1h582v402H-1z' }),
          React.createElement(
            'g',
            null,
            React.createElement('ellipse', { ry: '200', rx: '200', cy: '200.413', cx: '200', fill: '#EAEAEA' }),
            React.createElement('ellipse', { rx: '100', ry: '100', cy: '36', cx: '943.5', fill: '#EAEAEA' }),
            React.createElement('path', {
              d: 'M100 200h200M200 300',
              fill: 'none',
              stroke: '#000',
              strokeWidth: '20'
            })
          )
        )
      )
    );
  };

  return ZoomButtons;
}(Component);

export default withBemClass('zoom-buttons')(ZoomButtons);