var _class, _temp2;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import FlipMove from 'react-flip-move';
import { withBemClass } from '@canvas-panel/core';
import './PeekComponent.scss';

var PeekComponent = (_temp2 = _class = function (_Component) {
  _inherits(PeekComponent, _Component);

  function PeekComponent() {
    var _temp, _this, _ret;

    _classCallCheck(this, PeekComponent);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = { down: false, revert: false, lastOffset: 0, isTransitioning: false }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  PeekComponent.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.down && nextProps.down === false) {
      if (this.props.customOffset >= nextProps.threshold && this.props.index !== 0) {
        nextProps.onPrevious();
      } else if (this.props.customOffset <= -nextProps.threshold && this.props.index + 1 < this.props.size) {
        nextProps.onNext();
      }
    }
  };

  PeekComponent.prototype.render = function render() {
    var _props = this.props,
        down = _props.down,
        customOffset = _props.customOffset,
        index = _props.index,
        renderLeft = _props.renderLeft,
        renderRight = _props.renderRight,
        children = _props.children,
        bem = _props.bem;

    var x = customOffset;
    var shouldAnimate = down === false;

    return React.createElement(
      'div',
      { className: bem },
      React.createElement(
        'div',
        { className: bem.element('inner') },
        React.createElement(
          FlipMove,
          {
            typeName: null,
            enterAnimation: 'none',
            leaveAnimation: 'none',
            duration: 300
          },
          React.createElement(
            'div',
            {
              key: index - 1,
              className: bem.element('wing').modifier('left'),
              style: {
                left: 'calc(-100% + ' + (x - 20) + 'px)',
                transition: shouldAnimate ? 'left .2s' : null
              }
            },
            renderLeft()
          ),
          React.createElement(
            'div',
            {
              key: index,
              className: bem.element('wing').modifier('center'),
              style: {
                left: !down ? 'calc(0px + ' + x + 'px)' : null
              }
            },
            children
          ),
          React.createElement(
            'div',
            {
              className: bem.element('wing').modifier('right'),
              key: index + 1,
              style: {
                left: 'calc(100% + ' + (x + 20) + 'px)',
                transition: shouldAnimate ? 'left .2s' : null
              }
            },
            renderRight()
          )
        )
      )
    );
  };

  return PeekComponent;
}(Component), _class.defaultProps = {
  threshold: 75,
  customOffset: 0,
  lastOffset: 0,
  onNext: function onNext() {
    return null;
  },
  onPrevious: function onPrevious() {
    return null;
  }
}, _temp2);


export default withBemClass('peek-component')(PeekComponent);