function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import { withBemClass } from '@canvas-panel/core';

import './CanvasNavigation.scss';

var CanvasNavigation = function (_Component) {
  _inherits(CanvasNavigation, _Component);

  function CanvasNavigation() {
    _classCallCheck(this, CanvasNavigation);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  CanvasNavigation.prototype.render = function render() {
    var _props = this.props,
        previousRange = _props.previousRange,
        nextRange = _props.nextRange,
        canvasList = _props.canvasList,
        currentIndex = _props.currentIndex,
        bem = _props.bem;


    var size = canvasList ? canvasList.length : this.props.size;
    return React.createElement(
      'div',
      { className: bem },
      React.createElement(
        'button',
        {
          className: bem.element('previous').modifiers({ isFirstPage: currentIndex === 0 }),
          onClick: function onClick(ev) {
            ev.preventDefault();
            previousRange();
          }
        },
        React.createElement(
          'svg',
          { viewBox: '0 0 100 100', width: '20px', height: '20px' },
          React.createElement('path', { fill: 'none', d: 'M-1-1h582v402H-1z' }),
          React.createElement(
            'g',
            null,
            React.createElement('path', {
              d: 'M70.173 12.294L57.446.174l-47.62 50 47.62 50 12.727-12.122-36.075-37.879z',
              fill: 'currentColor',
              fillRule: 'nonzero'
            })
          )
        )
      ),
      React.createElement(
        'button',
        {
          className: bem.element('next').modifiers({ isLastPage: currentIndex === size - 1 }),
          onClick: function onClick(ev) {
            ev.preventDefault();
            nextRange();
          }
        },
        React.createElement(
          'svg',
          {
            viewBox: '0 0 100 100',
            width: '20px',
            height: '20px'
          },
          React.createElement('path', { fill: 'none', d: 'M-1-1h582v402H-1z' }),
          React.createElement(
            'g',
            null,
            React.createElement('path', {
              d: 'M20 88.052l12.727 12.121 47.62-50-47.62-50L20 12.294l36.075 37.88z',
              fill: 'currentColor',
              fillRule: 'nonzero'
            })
          )
        )
      )
    );
  };

  return CanvasNavigation;
}(Component);

export default withBemClass('canvas-navigation')(CanvasNavigation);