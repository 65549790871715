var _class, _temp2;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import * as Manifesto from 'manifesto.js';
import * as PropTypes from 'prop-types';
import functionOrMapChildren, { FunctionOrMapChildrenType } from '../../utility/functionOrMapChildren';

var Manifest = (_temp2 = _class = function (_Component) {
  _inherits(Manifest, _Component);

  function Manifest() {
    var _temp, _this, _ret;

    _classCallCheck(this, Manifest);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, _Component.call.apply(_Component, [this].concat(args))), _this), _this.state = {
      error: null,
      manifest: null
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  Manifest.prototype.componentWillMount = function componentWillMount() {
    var _this2 = this;

    var _props = this.props,
        url = _props.url,
        jsonLd = _props.jsonLd,
        fetchOptions = _props.fetchOptions;


    if (jsonLd) {
      this.setState({ manifest: this.create(jsonLd) });
      return;
    }

    fetch(url, fetchOptions || { cache: 'force-cache' }).then(function (j) {
      return j.json();
    }).then(function (fetchedJsonLd) {
      _this2.setState({
        manifest: _this2.create(fetchedJsonLd)
      });
    }).catch(function (error) {
      console.error('error', error);
      _this2.setState({
        error: 'something went wrong fetching this manifest.'
      });
    });
  };

  Manifest.prototype.create = function create(jsonLd) {
    return Manifesto.create(jsonLd, { locale: this.props.locale });
  };

  Manifest.prototype.render = function render() {
    var children = this.props.children;
    var _state = this.state,
        manifest = _state.manifest,
        error = _state.error;


    if (error) {
      return React.createElement(
        'div',
        null,
        error
      );
    }

    if (manifest === null) {
      return React.createElement(
        'div',
        null,
        'Loading...'
      );
    }

    return functionOrMapChildren(children, { manifest: manifest });
  };

  return Manifest;
}(Component), _class.defaultProps = {
  locale: 'en-GB'
}, _temp2);
Manifest.propTypes = process.env.NODE_ENV !== "production" ? {
  /** URL of IIIF Manifest to load */
  url: PropTypes.string,
  /** Function children that will be passed a manifest, and return JSX */
  children: FunctionOrMapChildrenType.isRequired,
  /** Locale to fetch the manifest */
  locale: PropTypes.string,
  /** Fetch options for manifest retrieval **/
  fetchOptions: PropTypes.object
} : {};


export default Manifest;